import $ from 'jquery'
import prestashop from 'prestashop'
import modals from './modals'
import {debounce} from "../_helpers"

let state = {
    selector: $('nav.navigation'),
    listItem: '.nav_main__items--submenu',
    wrapper: '.submenu__items--wrapper',
    wrapperContent: '.nav_main__content',
    wrapperSelector: ".submenu__wrapper.first_rows",
    maxVisibleSubcategories: 2,
    navTree: null,
    currentList: null,
    isDeep: false
}

$(function () {
    loadNavigationTree()
    setResizeListeners()
    // loadMobileNavigation()
    $(document).on('click', '.js-toggle-navbar', toggleNavbar)
})

// Load mobile nav
const loadMobileNavigation = function () {
    $(document).on('click', '.submenu__items--wrapper', displaySecondaryLevel)
    $(document).on('click', '.deeplevel__item .chevron__clickable', displayDeepLevel)
    $(document).on('click', '.go__back--list', goBack)
    $(document).on('click', '.nav__primary--close', closeNavbar)
}

const toggleNavbar = function (e) {
    e.preventDefault()
    const modals = prestashop.themeSelectors.modals // Selector for main classes

    if (!$('.modal__underlay').hasClass('active')) {
        $(modals.underlay).removeClass('hidden')
        setTimeout(() => {
            $(modals.underlay).addClass(modals.activeClass)
        })
    } else modals.closeModal()
    $('.nav__primary').toggleClass('active')
}

const closeNavbar = function (e) {
    e.preventDefault()

    modals.closeModal()
    $('.nav__primary').removeClass('active')
}

// SecondaryLevel
const displaySecondaryLevel = function (e) {
    const id = $(e.currentTarget).parent().attr('data-id')
    state.currentList = state.navTree.filter(item => item.id === id)[0]

    if (state.currentList.sublist.length === 1) {
        appendItems(state.currentList.sublist[0])
    } else {
        appendItems(state.currentList)
    }
}

// Deeplevel item
const displayDeepLevel = function (e) {
    e.preventDefault()

    const current = state.currentList.sublist.filter(item => item.id === $(this).parent().attr('data-id'))[0]

    appendItems(current)
    state.isDeep = true
}

// Go back
const goBack = function (e) {
    if (state.isDeep) {
        appendItems(state.currentList)
    } else {
        $('.navigation__wrapper').css({display: ''})
        $('.main-menu__details').remove()
    }

    state.isDeep = false
}

// Apend items
const appendItems = function (item) {
    if (!$('.main-menu__detailslist').length) {
        $('.navigation__wrapper').after(
            `<div class="main-menu__details">
            <div class="go__back--list row row-nowrap middle-xs">
                <span class="go__back--title-t">${item.title}</span>
            </div>

            <div class="main-menu__detailslist"></div>
        </div>`
        )
    } else {
        $('.go__back--title-t').text(item.title)
        $('.main-menu__detailslist').html('')
    }

    $.each(item.sublist, function (key, value) {
        $('.main-menu__detailslist')
            .append(`
            <div
                class="deeplevel__item row${value?.sublist?.length ? ' chevron' : ''}" 
                data-id="${value.id ?? ''}"
                >
                <a ${value.link ? 'href="' + value.link + '"' : ''}>${value.title}</a>
                ${value?.sublist?.length ? '<div class="chevron__clickable"></div>' : ''}
            </div>
        `)
    })

    $('nav.navigation .navigation__wrapper').css({display: 'none'})
}

// Load navigation tree
const loadNavigationTree = function () {
    let list = []
    const listitems = $('nav.navigation').find('.list__item')

    $.each(listitems, function (key, value) {
        let sublist = []
        let items = []

        if ($(value).hasClass('nav_main__items--submenu')) {
            items = $(value).find('.nav_main__cell-name')
            sublist = findSublist(items)
        }

        list.push({
            id: $(value).attr('data-id'),
            title: $(value).find('.navigation__link').text().trim(),
            link: $(value).find('.navigation__link').attr('href'),
            sublist: sublist ?? null
        })
    })
    state.navTree = list
}

const findSublist = function (sublistItems) {
    let sublist = []
    for (let index = 0; index < sublistItems.length; index++) {
        let childs = $(sublistItems[index]).siblings('.column').children()
        let hasChilds = childs.length

        let title = ''

        if ($(sublistItems[index]).hasClass('nav_main__cell-name')) {
            title = $(sublistItems[index]).text().trim()
        } else {
            title = $(sublistItems[index]).find('.nav_main__cell-block .nav_main__cell-title').text().trim()
        }

        sublist.push({
            id: $(sublistItems[index]).parent().attr('data-id') ?? null,
            title,
            link: $(sublistItems[index]).find('a').attr('href'),
            sublist: hasChilds ? findSublist(childs) : null
        })
    }

    return sublist
}

// Handle updates when screen is resized
const setResizeListeners = function () {
    window.addEventListener("resize", debounce(loadMobileNavigation))
}
