import $ from 'jquery'
import prestashop from 'prestashop'

$(document).ready(() => {
    const scrollTo = prestashop.themeSelectors.scrollTo

    $(document).on("click", `${scrollTo.trigger}`, (e) => {
        e.preventDefault()
        const self = $(e.currentTarget)
        const dataAS = $(e.currentTarget).attr('data-additional-spacing')

        let additionalSpacing = 0

        if (dataAS) additionalSpacing = dataAS

        window.scrollTo({
            top: parseInt($(`${scrollTo.target}${$(self).attr('href')}`)
                .filter(":visible").offset().top) - parseInt(additionalSpacing),
            behavior: 'smooth'
        })
        $(`#${$(self).attr('data-target')}`).trigger($(self).attr('data-trigger'))
    })
})
