import $ from 'jquery'
import prestashop from 'prestashop'

let state = {
    defaultTiming: 0,
    defaultDelay: 0
}

const init = function (options) {
    // Set state
    state.container = options?.container ? options.container + ' ' : ''

    // Animate on image load selectors
    const onImageLoad = $(`${state.container} *[data-sa-animate='image-load']`)
    // Animate on page load
    const onPageLoad = $(`${state.container} *[data-sa-animate='load']`)

    if (onImageLoad.length) animateOnImageLoad(onImageLoad)
    if (onPageLoad.length) animateOnPageLoad(onPageLoad)
}

const animateOnImageLoad = function (onImageLoad) {
    $.each(onImageLoad, function (key, value) {
        const timing = $(value).data('sa-timing') ?? state.defaultTiming
        const delay = $(value).data('sa-delay') ?? state.defaultDelay

        // Run onload code.
        const imageLoaded = function () {
            setTimeout(function () {
                $(value).css({transition: '0.' + timing + 's ease-in'})
                $(value).addClass('animating')

                setTimeout(function () {
                    $(value).addClass('animated')
                }, timing)
            }, delay)
        }

        const imageError = function (e) {
            $(value).attr('src', prestashop.urls.no_picture_image.large.url)
            imageLoaded()
        }

        let tmpImg = new Image()
        tmpImg.onload = imageLoaded
        tmpImg.onerror = imageError
        tmpImg.src = $(value).attr('src')
    })
}

const animateOnPageLoad = function (onPageLoad) {
    $.each(onPageLoad, function (key, value) {
        const timing = $(value).data('sa-timing') ?? state.defaultTiming
        const delay = $(value).data('sa-delay') ?? state.defaultDelay

        // Run onload code.
        setTimeout(function () {
            $(value).css({transition: '0.' + timing + 's ease-in'})
            $(value).addClass('animating')

            setTimeout(function () {
                $(value).addClass('animated')
            }, timing)
        }, delay)
    })
}

export default init

export {
    animateOnPageLoad,
    animateOnImageLoad
}