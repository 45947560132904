import $ from "jquery"
import tippy from "tippy.js"

$(document).ready(() => {
  $(".tippy__dropdown").each(handleDropdown)
})

const handleDropdown = (i, v) => {
  const currentID = $(v).attr("id")

  tippy(`#${currentID}`, {
    content: $(`#${currentID}`).find(".tippy__dropdown--content").html(),
    allowHTML: true,
    trigger: "click",
    theme: "white",
    interactive: true,
  })
}