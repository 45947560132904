import $ from "jquery"
import prestashop from "prestashop"
import loadVideo, {playVideo} from "./components/product/product-video"
import gallery, {initThumbs} from "./components/product/product-gallery"
import addToCart from "./components/product/add-to-cart"
import stickyBar from "./components/product/stickybar"
import modules from './components/product/modules'

if (prestashop.page.page_name == 'product') {
    $(function () {
        loadVideo()
        gallery()
        addToCart()
        modules()
        stickyBar()

        // On product update re-initiate thumbs slider
        prestashop.on('updatedProduct', function (event) {
            initThumbs()
            $('.nice-select').niceSelect()
            $('.product__container .sa-fade-in').addClass('animating animated')

            // Update product reference if combination;
            if (event.product_reference.length !== 0) {
                $('.product-reference span').text(event.product_reference)
            }

            //// Update product add to cart block;
            $(".page-product:not(.modal-open) .product__container .product__description--details").first().replaceWith(event.product_info_header)
            $(".page-product:not(.modal-open) .product__container .product-add-to-cart").first().replaceWith(event.product_add_to_cart)

            // Refresh ratings;
            setTimeout(function(){  $(".grade-stars").rating() }, 1000);


            $('#product_elp_quantity').val(event.product_qty)
        })

    })
}
