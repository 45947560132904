(function($) {

    $.fn.niceSelect = function(method) {

        // Methods
        if (typeof method == 'string') {
            if (method == 'update') {
                this.each(function() {
                    var $select = $(this);
                    var $dropdown = $(this).next('.nice-select');
                    var open = $dropdown.hasClass('open');

                    if ($dropdown.length) {
                        $dropdown.remove();
                        create_nice_select($select);

                        if (open) {
                            $select.next().trigger('click');
                        }
                    }
                });
            } else if (method == 'destroy') {
                this.each(function() {
                    var $select = $(this);
                    var $dropdown = $(this).next('.nice-select');

                    if ($dropdown.length) {
                        $dropdown.remove();
                        $select.css('display', '');
                    }
                });
                if ($('.nice-select').length == 0) {
                    $(document).off('.nice_select');
                }
            } else {
                console.log('Method "' + method + '" does not exist.')
            }
            return this;
        }

        // Hide native select
        this.hide();

        // Create custom markup
        this.each(function() {
            var $select = $(this);
            if (!$select.next().hasClass('nice-select')) {
                create_nice_select($select);
            }
        });

        function create_nice_select($select) {
            // Variables
            const $class = $select.attr('class')
            const $disabled = $select.attr('disabled')
            const $multiple = $select.data('search-multiple')
            const $colors = $select.data('select-colors')
            const $search = $select.data('select-search')
            const $attrs = $select.data('select-attrs')

            // Components
            const search = $search ? `<div class="nice-select-search-box">
                        <input type="text" class="nice-select-search" placeholder="Paieška"/>
                    </div>` : ''

            const placeholder = $multiple ?
                `<span class="multiple-options"></span>` :
                `<span class="current"></span>`

            // Logic
            $select.after($('<div></div>')
                .addClass('nice-select')
                .addClass($class || '')
                .addClass($disabled ? 'disabled' : '')
                .addClass($multiple ? 'has-multiple' : '')
                .addClass($search ? 'has-search' : '')
                .attr('tabindex', $disabled ? null : '0')
                .html(`${placeholder}${search}<ul class="list"></ul>`)
            );

            var $dropdown = $select.next();
            var $options = $select.find('option');
            var $optgroups = $select.find('optgroup');
            if ($multiple) {
                var $selected = $select.find('option:selected');
                var $selected_html = '';
                $selected.each(function() {
                    $selected_option = $(this);
                    $selected_text = $selected_option.data('display') ||  $selected_option.text();

                    if (!$selected_option.val()) {
                        return;
                    }

                    $selected_html += '<span class="current">' + $selected_text + '</span>';
                });

                $select_placeholder = $select.data('multi-placeholder') || $select.attr('multi-placeholder');
                $select_placeholder = !$select_placeholder ? 'Select' : $select_placeholder;
                $selected_html = $selected_html === '' ? $select_placeholder : $selected_html;
                $dropdown.find('.multiple-options').html($selected_html);
            } else {
                var $selected = $select.find('option:selected');
                $dropdown.find('.current').html($selected.data('display') ||  $selected.text());
            }

            if($optgroups.length) {
                appendOptGroups()
            } else {
                appendOptions($dropdown.find('ul'), $options)
            }

            function appendOptGroups () {
                $optgroups.each(function(i) {
                    let $group = $(this);
                    let groupItem = $(`<li></li>`)
                    let groupTitle = `<div class="group-title">${$($group).attr('label')}</div>`

                    $dropdown.find('ul:first')
                        .addClass('group-list')
                        .append(groupItem.addClass('group-item').html(groupTitle + '<ul class="group-sublist"></ul>'))
                    appendOptions(groupItem.find('ul.group-sublist'), $group.find('option'))
                })
            }


            function appendOptions (selector, options) {
                options.each(function(i) {
                    let $option = $(this);
                    let display = $option.data('display')
                    let listItemAdditional = ''
                    let listItem = $('<li></li>')

                    // Apply all data keys if necessary
                    if($attrs) {
                        Object.keys($option.data()).map((key, index) =>
                            listItem.attr(`data-${key}`, $option.data()[key])
                        );
                    }
                    // Apply color / texture if necessary
                    if($colors)
                        if($option.data('color'))
                            listItemAdditional = `<div class="option-color" style="background-color: ${$option.data('color')}"></div>`
                        if($option.data('texture'))
                            listItemAdditional = `<div class="option-color" style="background-image: url(${$option.data('texture')})"></div>`

                    selector.append(listItem
                        .attr('data-value', $option.val())
                        .attr('data-display', (display || null))
                        .addClass('option' +
                            ($option.is(':selected') ? ' selected' : '') +
                            ($option.is(':disabled') ? ' disabled' : ''))
                        .append(listItemAdditional + $option.text())
                    );
                });
            }
        }

        /* Event listeners */

        // Unbind existing events in case that the plugin has been initialized before
        $(document).off('.nice_select');

        // Open/close
        $(document).on('click.nice_select', '.nice-select', function(event) {
            var $dropdown = $(this);

            $('.nice-select').not($dropdown).removeClass('open');
            $dropdown.toggleClass('open');

            if ($dropdown.hasClass('open')) {
                $dropdown.find('.option');
                $dropdown.find('.nice-select-search').val('');
                $dropdown.find('.nice-select-search').focus();
                $dropdown.find('.focus').removeClass('focus');
                $dropdown.find('.selected').addClass('focus');
                $dropdown.find('ul li').show();
            } else {
                $dropdown.focus();
            }
        });

        $(document).on('click', '.nice-select-search-box', function(event) {
            event.stopPropagation();
            return false;
        });
        $(document).on('keyup.nice-select-search', '.nice-select', function() {
            var $self = $(this);
            var $text = $self.find('.nice-select-search').val();
            var $options = $self.find('ul li');
            if ($text == '')
                $options.show();
            else if ($self.hasClass('open')) {
                $text = $text.toLowerCase();
                var $matchReg = new RegExp($text);
                if (0 < $options.length) {
                    $options.each(function() {
                        var $this = $(this);
                        var $optionText = $this.text().toLowerCase();
                        var $matchCheck = $matchReg.test($optionText);
                        $matchCheck ? $this.show() : $this.hide();
                    })
                } else {
                    $options.show();
                }
            }
            $self.find('.option'),
                $self.find('.focus').removeClass('focus'),
                $self.find('.selected').addClass('focus');
        });

        // Close when clicking outside
        $(document).on('click.nice_select', function(event) {
            if ($(event.target).closest('.nice-select').length === 0) {
                $('.nice-select').removeClass('open').find('.option');
            }
        });

        // Option click
        $(document).on('click.nice_select', '.nice-select .option:not(.disabled)', function(event) {

            var $option = $(this);
            var $dropdown = $option.closest('.nice-select');
            if ($dropdown.hasClass('has-multiple')) {
                if ($option.hasClass('selected')) {
                    $option.removeClass('selected');
                } else {
                    $option.addClass('selected');
                }
                $selected_html = '';
                $selected_values = [];
                $dropdown.find('.selected').each(function() {
                    $selected_option = $(this);
                    var attrValue = $selected_option.data('value');
                    var text = $selected_option.data('display') ||  $selected_option.text();
                    $selected_html += (`<span class="current" data-id=${attrValue}> 
                        ${text} 
                        <span class="remove">
                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                viewBox="0 0 512.001 512.001" style="enable-background:new 0 0 512.001 512.001;">
                                    <path d="M284.286,256.002L506.143,34.144c7.811-7.811,7.811-20.475,0-28.285c-7.811-7.81-20.475-7.811-28.285,0L256,227.717
                                    L34.143,5.859c-7.811-7.811-20.475-7.811-28.285,0c-7.81,7.811-7.811,20.475,0,28.285l221.857,221.857L5.858,477.859
                                    c-7.811,7.811-7.811,20.475,0,28.285c3.905,3.905,9.024,5.857,14.143,5.857c5.119,0,10.237-1.952,14.143-5.857L256,284.287
                                    l221.857,221.857c3.905,3.905,9.024,5.857,14.143,5.857s10.237-1.952,14.143-5.857c7.811-7.811,7.811-20.475,0-28.285
                                    L284.286,256.002z"/>
                                </svg>
                            </span>
                        </span>`);
                    $selected_values.push($selected_option.data('value'));
                });
                $select_placeholder = $dropdown.prev('select').data('js-placeholder') ||                                   $dropdown.prev('select').attr('js-placeholder');
                $select_placeholder = !$select_placeholder ? 'Select' : $select_placeholder;
                $selected_html = $selected_html === '' ? $select_placeholder : $selected_html;
                $dropdown.find('.multiple-options').html($selected_html);
                $dropdown.prev('select').val($selected_values).trigger('change');
            } else {
                $dropdown.find('.selected').removeClass('selected');
                $option.addClass('selected');
                var text = $option.data('display') || $option.text();
                $dropdown.find('.current').text(text);
                $dropdown.prev('select').val($option.data('value')).trigger('change');
            }
        });
        //---------remove item
        $(document).on('click','.remove', function(){
            var $dropdown = $(this).parents('.nice-select');
            var clickedId = $(this).parent().data('id')
            $dropdown.find('.list li').each(function(index,item){
                if(clickedId == $(item).attr('data-value')) {
                    $(item).removeClass('selected')
                }
            })
            $selected_values.forEach(function(item, index, object) {
                if (item === clickedId) {
                    object.splice(index, 1);
                }
            });
            $(this).parent().remove();
        })

        // Keyboard events
        $(document).on('keydown.nice_select', '.nice-select', function(event) {
            var $dropdown = $(this);
            var $focused_option = $($dropdown.find('.focus') || $dropdown.find('.list .option.selected'));

            // Space or Enter
            if (event.keyCode == 32 || event.keyCode == 13) {
                if ($dropdown.hasClass('open')) {
                    $focused_option.trigger('click');
                } else {
                    $dropdown.trigger('click');
                }
                return false;
                // Down
            } else if (event.keyCode == 40) {
                if (!$dropdown.hasClass('open')) {
                    $dropdown.trigger('click');
                } else {
                    var $next = $focused_option.nextAll('.option:not(.disabled)').first();
                    if ($next.length > 0) {
                        $dropdown.find('.focus').removeClass('focus');
                        $next.addClass('focus');
                    }
                }
                return false;
                // Up
            } else if (event.keyCode == 38) {
                if (!$dropdown.hasClass('open')) {
                    $dropdown.trigger('click');
                } else {
                    var $prev = $focused_option.prevAll('.option:not(.disabled)').first();
                    if ($prev.length > 0) {
                        $dropdown.find('.focus').removeClass('focus');
                        $prev.addClass('focus');
                    }
                }
                return false;
                // Esc
            } else if (event.keyCode == 27) {
                if ($dropdown.hasClass('open')) {
                    $dropdown.trigger('click');
                }
                // Tab
            } else if (event.keyCode == 9) {
                if ($dropdown.hasClass('open')) {
                    return false;
                }
            }
        });

        // Detect CSS pointer-events support, for IE <= 10. From Modernizr.
        var style = document.createElement('a').style;
        style.cssText = 'pointer-events:auto';
        if (style.pointerEvents !== 'auto') {
            $('html').addClass('no-csspointerevents');
        }

        return this;

    };

}(jQuery));