import $ from "jquery"

const config = {
    dealsSelector: '.product__multiaccessories .swiper-container',
    dealsConfig: {
        navigation: {
            nextEl: ".product__multiaccessories .swiper-button-next",
            prevEl: ".product__multiaccessories .swiper-button-prev"
        },
        // shortSwipes: false,
        slidesPerView: 1,
        spaceBetween: 80,
        pagination: {
            el: '.product__multiaccessories .swiper-pagination',
            clickable: true
        },
        breakpoints: {
            993: {
                slidesPerView: 3
            },
            767: {
                slidesPerView: 2
            }
        }
    }
}

export default config