import $ from "jquery"
import * as yup from "yup"
import toastr from 'toastr'

$(document).ready(() => {
    window.displayErrorMessages = displayErrorMessages
    window.validateInputs = validateInputs

    // Remove errors on input blur / state change
    // Nice little interactive effect
    $(document).on('change', '.input__group.error input, .input__group.error textarea, .input__group.error select', function (e) {
        $(this).parents('.input__group').removeClass('error')
        $(this).parents('.input__wrapper').find('.alert').remove()
    })
})

const validateInputs = (inputs) => {
    const clang = $("html").attr("lang")
    let inputValues = {}
    let validation = []

    // Get new values
    inputs.each((i, v) => {
        let el = $(v)
        let value = null

        if (el.attr("type") === "checkbox") value = el.is(":checked")
        else value = el.val()

        let dataList = el.data()

        inputValues[el.attr("id")] = value

        let obj = {
            id: el.attr("id"),
            validationType: el.data("yup-type"),
            value,
            validations: []
        }

        // Check if input is required before
        // Making any other validation
        if (dataList.yupRequired) {
            // Test required field
            obj.validations.push({
                type: "required",
                params: [locale[clang].required]
            })

            // Test minimum value
            if (dataList.yupMin)
                obj.validations.push({
                    type: "min",
                    params: [dataList.yupMin, locale[clang].min]
                })

            // Test maximum value
            if (dataList.yupMax)
                obj.validations.push({
                    type: "max",
                    params: [dataList.yupMax, locale[clang].max]
                })

            // If checkbox was marked as required
            // Test for true only values
            if (el.attr("type") === "checkbox")
                obj.validations.push({
                    type: "oneOf",
                    params: [[true], locale[clang].checked]
                })
        }

        if (obj?.id?.indexOf('email') !== -1) {
            obj.validations.push({
                type: 'email',
                params: [
                    locale[clang].email
                ]
            })
        }

        // // Validate phone numbers
        // if (obj.id === "phone_mobile" || obj.id === 'phone') {
        //     obj.validations = [
        //         {
        //             type: "test",
        //             params: [
        //                 "phone_mobile",
        //                 locale[clang].phone,
        //                 (value) => {
        //                     if (value !== "") {
        //                         const phoneUtil = require("google-libphonenumber").PhoneNumberUtil.getInstance()
        //                         let test = value
        //
        //                         try {
        //                             test = phoneUtil.isValidNumberForRegion(
        //                                 phoneUtil.parse(value, clang.toUpperCase()),
        //                                 clang.toUpperCase()
        //                             )
        //                             if (test)
        //                                 test = phoneUtil.isValidNumber(
        //                                     phoneUtil.parse(value, clang.toUpperCase())
        //                                 )
        //                         } catch (err) {
        //                             test = false
        //                         }
        //
        //                         return test ? value : false
        //                     }
        //                     return false
        //                 },
        //             ],
        //         },
        //     ]
        // }
        validation.push(obj)
        console.log('validation', validation)
    })

    const yepSchema = validation.reduce(createYupSchema, {})

    const schema = yup.object().shape(yepSchema)

    // Validate by schema
    const response = schema
        .validate(inputValues, {abortEarly: false})
        .then(function () {
            return true
        })
        .catch(function (err) {
            // Display new errors
            let errorsList = {}
            console.log('err.inner ', err.inner)
            err.inner.forEach((e) => {
                if (!errorsList[e.path]) {
                    errorsList[e.path] = {}
                    errorsList[e.path].messages = ""
                }
                errorsList[e.path].messages += `<div class="alert alert--red row middle center"><span class="highlighted">${e.message}</span></div>`
                errorsList[e.path].id = e.path
            })

            displayErrorMessages(errorsList)

            return false
        })

    return response
}

const locale = {
    lt: {
        required: "Privalomas laukelis",
        min: "name cannot be less than x characters",
        max: "name cannot be more than x characters",
        checked: "Privalote pažymėti",
        phone: "Įveskite teisingą telefoną",
        email: "Įveskite teisingą El. pašta"
    },
    lv: {
        required: "this field is required",
        min: "name cannot be less than x characters",
        max: "name cannot be more than x characters",
        checked: "input must be checked",
        phone: "Invalid phone number",
        email: "Invalid email"
    }
}

const displayErrorMessages = (list) =>
    Object.keys(list).forEach(function (key) {
        if (key !== "global_error") {
            const selector = $(`input#${key}, textarea#${key}, select#${key}`)

            if (selector.length)
            {
                const currentErrors = $(`input#${key}`).parents('.input__wrapper').find('.alert')
                if (currentErrors.length) currentErrors.remove()
                selector.parents('.input__group').addClass('error')
                selector.parents('.input__wrapper').append(list[key].messages)
            }
            else
            {
                // return toast error message when selector not found
                toastr.error(list[key].messages, 'Klaida!')
            }
        } else {
            toastr.error(list.global_error.messages, 'Klaida!')
        }
    })

const createYupSchema = (schema, config) => {
    const {id, validationType, validations = []} = config
    if (!yup[validationType]) {
        return schema
    }
    let validator = yup[validationType]()
    validations.forEach((validation) => {
        const {params, type} = validation
        if (!validator[type]) {
            return
        }
        validator = validator[type](...params)
    })
    schema[id] = validator
    return schema
}

export {
    validateInputs,
    displayErrorMessages
}
