import $ from "jquery"
import Swiper from "swiper"

// Global vars
let gallery = []
let active = null

// Init product gallery
const initGalerry = () => {
    $(document).on('click', '.js-zoom-in-cover', zoomIn)
    $(document).on('click', '.thumb__item', function (e) {
        if (window.matchMedia('(max-width: 992px)').matches) zoomIn(e, true)
    })

    initThumbs()
    // initStickyBar()
}

// Init thumbnails slider
const initThumbs = () => {
    // Select active
    $('.thumb__item').on('click', selectActive)
    $('.thumb__item').eq(0).addClass('active')

    new Swiper('.product__thumbnails .swiper-container', {
        slidesPerView: 'auto',
        spaceBetween: 5,
        direction: "horizontal",
        centeredSlides: true,
        loop: true,
        navigation: {
            nextEl: ".product__thumbnails .swiper-button-next",
            prevEl: ".product__thumbnails .swiper-button-prev"
        },
        pagination: {
            el: ".product__thumbnails .swiper-pagination",
            clickable: true,
            dynamicBullets: true
        },
        breakpoints: {
            1361: {
                spaceBetween: 10,
                direction: "vertical",
                centeredSlides: false,
                loop: false
            },
            992: {
                spaceBetween: 10,
                direction: "horizontal",
                centeredSlides: false,
                loop: false
            }
        }
    })
}

// Open fullscreen picture
const zoomIn = (e, isThumb) => {
    $('body').css({overflow: 'hidden'})

    let image
    if(isThumb) image = $(e.currentTarget).find('img.thumb').get(0)
    else image = document.querySelector('.product__cover')

    const imagePos = image.getBoundingClientRect()

    let portal
        if(isThumb) {
            portal = $('<div class="product__cover"></div>')
            portal.append($(image).clone())
        } else {
            portal = $(image).clone()
        }


    portal.addClass('product__image--portal').css({
        position: 'fixed',
        opacity: 1,
        top: imagePos.top,
        left: imagePos.left,
        width: imagePos.width,
        height: imagePos.height,
        transition: '0.2s ease-in',
        zIndex: 200000000010
    })
        .removeClass('js-zoom-in-cover')
        .find('.zoom__in').remove()

    $('body').append(portal)

    setTimeout(() => {
        portal.css({
            top: 0,
            left: 0,
            opacity: 1,
            width: '100%',
            height: '100%'
        })
    }, 100)

    displayControls(portal)
    getGalleryItems()
}


// Close gallery
const closeGallery = (e) => {
    // Animate
    $('.product__image--portal').css({opacity: 0})
    // Before removing item
    setTimeout(() => {
        $('.product__image--portal').remove()
        $('body').css({overflow: ''})
    }, 200)
}


// Init gallery controls
const displayControls = (portal) => {
    // We want a nice delay before displaying controls.
    setTimeout(() => {
        if ($('.product__thumbnails').find('.js-thumb').length >= 2) {
            portal.append(`<div class='swiper-button swiper-button-prev'></div>`)
            portal.append(`<div class='swiper-button swiper-button-next'></div>`)
        }
        portal.append(`<div class='product__image--close'><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"viewBox="0 0 512.001 512.001" style="enable-background:new 0 0 512.001 512.001" xml:space="preserve"><path d="M284.286,256.002L506.143,34.144c7.811-7.811,7.811-20.475,0-28.285c-7.811-7.81-20.475-7.811-28.285,0L256,227.717L34.143,5.859c-7.811-7.811-20.475-7.811-28.285,0c-7.81,7.811-7.811,20.475,0,28.285l221.857,221.857L5.858,477.859c-7.811,7.811-7.811,20.475,0,28.285c3.905,3.905,9.024,5.857,14.143,5.857c5.119,0,10.237-1.952,14.143-5.857L256,284.287l221.857,221.857c3.905,3.905,9.024,5.857,14.143,5.857s10.237-1.952,14.143-5.857c7.811-7.811,7.811-20.475,0-28.285L284.286,256.002z"/></svg></div>`)
        portal.append(`<div class='product__image--download'></div>`)
        // portal.append(`<div class='product__image--bar'><div class='bar__inner' style='width: ${100 / active}%'></div></div>`)
        $('.product__image--close').on('click', closeGallery)
        $('.product__image--portal .swiper-button-prev').on('click', prevItem)
        $('.product__image--portal .swiper-button-next').on('click', nextItem)
    }, 300)
}

// Get gallery items
const getGalleryItems = () => {
    const galleryItems = $('.product__thumbnails').find('.thumb__item')

    $.each(galleryItems, (key, value) => {
        gallery.push({
            img: $(value).find('.js-thumb').data('image-large-src')
        })

        if ($(value).hasClass('active')) {
            active = key
        }
    })
}

// Handle prev button
const prevItem = (e) => {
    if (active === 0) {
        active = gallery.length - 1
    } else {
        active = active - 1
    }
    $('.product__image--portal img').css({
        transition: '0.2s ease-in',
        opacity: 0
    })

    setTimeout(() => {
        $('.product__image--portal img').attr('src', gallery[active].img).css({
            opacity: 1
        })
    }, 200)
}

// Handle next button
const nextItem = (e) => {
    if (gallery.length - 1 === active) active = 0
    else active += 1

    $('.product__image--portal img').css({
        transition: '0.2s ease-in',
        opacity: 0
    })

    setTimeout(() => {
        let img = $('.product__image--portal img')
        img.on('load', function() {
            img.css({
                opacity: 1
            })
        })
        img.attr('src', gallery[active].img)
    }, 200)
}

// Set image as active

let allow = true
const selectActive = (e) => {
    if (allow && $(e.currentTarget).hasClass('active') === false) {
        allow = false
        let selector = $('.product__cover.js-zoom-in-cover')

        $('.product__thumbnails').find('.active').removeClass('active')
        $(e.currentTarget).addClass('active')
        const data = $(e.currentTarget).find('.js-thumb').data('image-large-src')

        selector.find('img').css({opacity: 0})

        setTimeout(() => {
            selector.find('img').attr('src', data)
            selector.find('img').removeClass('animating')
            selector.find('img').removeClass('animated')
            selector.find('img').css({opacity: ''})
            window.simpleAnimations()
            allow = true
        }, 200)
    }
}

// If necessary add additional exports for easier control
export default initGalerry
export {
    initThumbs
}
