import $ from 'jquery'
import prestashop from 'prestashop'
import tippy from 'tippy.js'

// Globals
let lastScrollTop = 0
let allow = true
const selector = $('header#header')

$(function () {
    $(window).scroll(() => {
        if (allow && window.matchMedia("(min-width: 993px)").matches) {
            allow = false
            if (window.pageYOffset > window.innerHeight) {
                var st = $(this).scrollTop()
                if (st > lastScrollTop) {
                    removeSticky()
                } else {
                    addSticky()
                }
                lastScrollTop = st
            } else {
                removeSticky()
            }
        }
    })

    tippy('.my__account--settings', {
        content: $('.my__account--dropdown').clone().removeClass('invisible').html(),
        allowHTML: true,
        animation: 'shift-toward',
        interactive: true,
        interactiveBorder: 30,
        theme: 'white',
        zIndex: 10001
    })

    $('body').on('click', '.nav__primary--close', e => {
        e.preventDefault()
        $('.nav__primary').removeClass('active')
    })
})

const toggleMenu = e => {
    $(e.currentTarget).toggleClass('active')
    $('.nav__primary').toggleClass('active')
}

const removeSticky = () => {
    if (selector.hasClass('visible')) {
        selector.addClass('removing')
        setTimeout(() => {
            $('.header--placeholder').css({height: ''})
            selector.removeClass('visible sticky removing')
            allow = true
        }, 300)
    } else {
        allow = true
    }
}

const addSticky = () => {
    if (selector.hasClass('visible') === false) {
        $('.header--placeholder').css({height: selector.height()})
        selector.addClass('sticky')
        setTimeout(() => {
            selector.addClass('visible')
            allow = true
        }, 300)
    } else {
        allow = true
    }
}