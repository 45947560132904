import $ from "jquery"

$(document).ready(() => {
    window.buttonAddLoader = buttonAddLoader
    window.buttonRemoveLoader = buttonRemoveLoader

    // In some cases we need loader on redirect
    $(document).on('click', '.js-click-loader', onPressLoader)
})

const buttonAddLoader = (e) => {
    e.addClass("btn--disabled").addClass("loader--visible")
    e.find("span").css({opacity: 0})
}

const buttonRemoveLoader = e => {
    e.removeClass("btn--disabled").removeClass('loader--visible')
    e.find("span").css({opacity: ''})
}

const onPressLoader = e => {
    buttonAddLoader($(e.currentTarget))
}

// Exported functions
const debounce = function (func) {
    var timer
    return function (event) {
        if (timer) clearTimeout(timer)
        timer = setTimeout(func, 300, event)
    }
}

export {
    debounce
}