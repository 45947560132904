import $ from 'jquery'
import prestashop from 'prestashop'

if (prestashop.page.page_name == 'cart') {
    $(document).ready(() => {
        $('#checkout-personal-information-step, #checkout-delivery-step').on('click', function (e) {
            localStorage.setItem('elp_coo', $(e.currentTarget).attr('id'))
            window.location.href = prestashop.urls.pages.order
        })

        $('.steps__wrapper .single__step#return-to-cart').addClass('active')

        $(document).on('click', '.cart-detailed-actions .btn.proceed', function (e) {
            window.buttonAddLoader($(e.currentTarget))
        })
    })
}