import $ from 'jquery'
import Swiper from 'swiper'

$(document).ready(() => {
    init()

    new Swiper('.js-tabs-list', {
        slidesPerView: 3
    })

    $(`.js-tabs-list`).on("mouseover", `.tabs__list__item`, mouseOver)
    $(`.js-tabs-list`).on("mouseleave", `.tabs__list__item`, init)
    $(`.js-tabs-list`).on("click", `.tabs__list__item`, select)
})

const init = () => {
    if ($('.js-tabs-list').length) {
        const active = $('.js-tabs-list').find('.tabs__list__item--active')
        const underline = $('.js-tabs-list').find('.underline')
        underline.css({
            width: active.outerWidth(),
            left: active.offset().left - $('.js-tabs-list .swiper-wrapper').offset().left
        })
        $('.section__categories .btn.btn--midgreen')
            .attr('href', $('.tabs__list__item--active')
                .find('a').attr('href'))
    }
}

const mouseOver = (e) => {
    e.preventDefault()

    const active = $(e.currentTarget)
    const underline = $('.js-tabs-list').find('.underline')
    underline.css({
        width: active.outerWidth(),
        left: active.offset().left - $('.js-tabs-list .swiper-wrapper').offset().left
    })
}

const select = (e) => {
    e.preventDefault()

    $('.js-tabs-list').find('.tabs__list__item--active').removeClass('tabs__list__item--active')
    const active = $(e.currentTarget)
    active.addClass('tabs__list__item--active')
    $('.tabs__list--contents .tab__content').addClass('hidden')
    $('.tabs__list--contents .tab__content#' + active.find('a').data('id_category')).removeClass('hidden')
}