import $ from "jquery"
import prestashop from "prestashop"

prestashop.cart = prestashop.cart || {}
prestashop.cart.active_inputs = null

// Global vars
const options = prestashop.themeSelectors.options
const promises = []


$(document).ready(() => {
    $("body").on("click", options.actions, handleCartAction)
    $(document).on('click', '.cart__popup .proceed', function (e) {
        window.buttonAddLoader($(e.currentTarget))
    })
    initOptions()

    prestashop.on(
        'updateCart',
        function (event) {
            var refreshURL = $('.blockcart').data('refresh-url')
            var requestData = {}
            const ajaxCart = prestashop.themeSelectors.ajaxCart

            if (event && event.reason && typeof event.resp !== 'undefined' && !event.resp.hasError) {

                // Collect all variants
                let attributes = {}
                $.each($(".variants__list"), function (index, value) {
                    const groupName = $(value).data("group-name")
                    const groupType = $(value).data("group-type")
                    let currentValue

                    if (groupType === "select" || groupType === 'color') currentValue = $(value).find("select").val()
                    else if (groupType === "radio" || groupType === 'quads')
                        currentValue = $(value).find("input:checked").val()

                    attributes[groupName] = currentValue
                })

                let id_accessories = ''
                let id_accessories_attributes = ''

                $.each($('.js-multiaccessories-body .product__deals--body'), function (key, value) {
                    if ($(value).find('input').is(':checked')) {
                        const separator = id_accessories === '' ? '' : ','
                        const attrseparator = id_accessories_attributes === '' ? '' : ','
                        id_accessories += separator + $(value).data('accessory-id')
                        id_accessories_attributes += attrseparator + $(value).data('accessory-attr')
                    }
                })

                requestData = {
                    id_customization: event.reason.idCustomization,
                    id_product_attribute: event.reason.idProductAttribute,
                    id_product: event.reason.idProduct,
                    action: event.reason.linkAction,
                    qty: event.quantity,
                    ...attributes
                }

                $.post(refreshURL, requestData).then(function (resp) {
                    const isActive = $('.blockcart').find('#blockcart-modal').hasClass('active')

                    $('.blockcart').replaceWith(resp.preview)

                    if (isActive) $('.blockcart').find('#blockcart-modal').removeClass('hidden').addClass('active')

                    $(ajaxCart.totalQuantity).text(event.resp.cart.products_count)

                    if (event.reason.openShoppingCart) {
                        $('.site__header--actions .trigger__modal[href="#blockcart-modal"]').trigger('click')
                    }

                    if (event.resp.cart.products.length) {
                        $('header#header .btn__cart').addClass('active')
                        $('header#header .btn__cart span').text(event.resp.cart.products_count)
                    } else {
                        $('header#header .btn__cart').removeClass('active')
                        $('header#header .btn__cart span').html()
                    }

                    window.buttonRemoveLoader($('.js-add-to-cart-trigger'))
                    window.buttonRemoveLoader($('.js-add-to-cart'))

                    if (event.callback) event.callback()
                }).fail(function (resp) {
                    prestashop.emit('handleError', {eventType: 'updateShoppingCart', resp: resp})
                })

            }
            if (event && event.resp && event.resp.hasError) {
                prestashop.emit('showErrorNextToAddtoCartButton', {errorMessage: event.resp.errors.join('<br/>')})
            }
        }
    )
})


// In some cases we don't want to call ajax straight away
// after increasing quantity. For example product page
const initOptions = () => {
    // Handle increasing value
    $(document).on("click", options.increase, (e) => {
        e.preventDefault()
        const amount = $(e.target).parent().find(options.amount)
        let max = parseInt(amount.attr("max"))
        if (amount.val() < max) amount.val(parseInt(amount.val()) + 1)
        amount.trigger('change')
    })

    // Handle decreasing value
    $(document).on("click", options.decrease, (e) => {
        e.preventDefault()
        const amount = $(e.target).parent().find(options.amount)
        let min = parseInt(amount.attr("min"))
        if (amount.val() > min) amount.val(parseInt(amount.val()) - 1)
        amount.trigger('change')
    })

    // Handle min/max
    $(document).on('change', '.js-quantity-value', function () {
        let self = $(this)
        let max = parseInt(self.attr('max'))
        let min = parseInt(self.attr('min'))
        let val = self.val()

        if (self.data('replace') === 'all') {
            self = $('.js-quantity-value')
        }
        if (val > max) {
            self.val(max)
        } else if (val < min) {
            self.val(min)
        }
    })

}

// Abort previous requests
const abortPreviousRequests = () => {
    let promise
    while (promises.length > 0) {
        promise = promises.pop()
        promise.abort()
    }
}

// Handle increase/decrease and remove functions AJAX
const handleCartAction = (event) => {
    event.preventDefault()
    const parent = $(event.target).parents('.cart__actions')
    const max = parent.find('.js-quantity-value').attr('max')
    const val = parent.find('.js-quantity-value').val()

    parent.addClass("disabled")

    const $target = $(event.currentTarget)
    const cartAction = $target.attr("href")
    const requestData = {
        ajax: "1",
        action: "update"
    }

    if (!cartAction) {
        return
    }

    // If out of stock. Ignore
    if ($(event.currentTarget).hasClass('add__item') && val == max) {
        parent.removeClass("disabled")
        toastr.error(parent.data('quantity-unavailable'), parent.data('fail-title'))
        return
    }

    // If last item. Remove it
    if ($target.hasClass('remove__item') && parseInt(parent.find('.js-quantity-value').val()) == 1) {
        parent.find('.js-remove-product').trigger('click')

        return
    }

    abortPreviousRequests()
    $.ajax({
        url: cartAction,
        method: "POST",
        data: requestData,
        dataType: "json",
        beforeSend(jqXHR) {
            promises.push(jqXHR)
        }
    })
        .then(resp => {
            if (resp.cart.products.length === 0) {
                if ($('body').attr('id') === 'cart') {
                    $('.section__sidebar--right').remove()
                }
            }

            const cartLabel = $('.nav__main .btn__cart span')
            if (resp.quantity < 1) {
                cartLabel.text('')
                cartLabel.addClass('hidden')
                $('.nav__main .btn__cart span').removeClass('active')
            } else {
                cartLabel.text(resp.quantity)
                cartLabel.removeClass('hidden')
                $('.nav__main .btn__cart span').addClass('active')
            }

            prestashop.emit('updateCart', {
                reason: {
                    idProduct: resp.id_product,
                    idProductAttribute: resp.id_product_attribute,
                    idCustomization: resp.id_customization,
                    linkAction: 'update',
                    cart: resp.cart
                },
                callback: () => parent.removeClass('disabled'),
                resp: resp
            })
        })
        .fail((resp) => {
            parent.removeClass('disabled')
        })
}