import $ from 'jquery'
import Swiper, {
    Navigation,
    Pagination,
    Autoplay
} from "swiper"
import prestashop from 'prestashop'
import simpleAnimations from './lib/simple-animations'

Swiper.use([Navigation, Pagination, Autoplay])

const selector = ".homepage__swiper .swiper-container"

const homeSwiperConfig = {
    slidesPerView: 1,
    loop: true,
    autoplay: true,
    navigation: {
        nextEl: selector + " .swiper-button-next",
        prevEl: selector + " .swiper-button-prev"
    },
    pagination: {
        el: selector + " .swiper-pagination",
        clickable: true
    },
    on: {
        init: function () {
            // Workaround for swiper width calculation issue.
            setTimeout(() => {
                window.dispatchEvent(new Event('resize'));
            }, 0)
        },
        slideChangeTransitionStart: function () {
            function relDiff(a, b) {
                return ((a - b) / (a)) * 100
            }

            let data = $('.homepage__swiper .swiper-container .swiper-slide-active .swiper__caption').data()
            let product = $('.homepage__swiper .slide__product')

            if (data && data.productId) {
                product.removeClass('hidden')
                product.attr('data-type', data.productType)
                product.attr('data-color', data.productColor)
                product.find('.slide__product__title').text(data.productName)
                let productRegularPrice = parseFloat(data.productRegularprice).toFixed(2)
                let productPrice = parseFloat(data.productCurrentprice).toFixed(2)

                let hasDiscount = parseInt(data.productHasdiscount);
                let discountValue = data.productDiscountvalue;

                product.find('.new__price').text(productPrice + ' ' + prestashop.currency.sign)

                if (hasDiscount) {
                    product.find('.old__price').text(productRegularPrice)
                    product.find('.slide__discount .flag').text('-' + discountValue + '%')
                    product.find('.flag').removeClass('hidden')

                } else {
                    product.find('.new__price').text(productPrice + ' ' + prestashop.currency.sign)
                    product.find('.old__price').text('') //no discount, set empty
                    product.find('.flag').addClass('hidden')
                }

                product.find('.slide__product-img a').attr('href', data.productUrl)
                product.find('.slide__product-img img').attr('src', data.productImage)
                simpleAnimations()
                product.find('a.btn').attr('href', data.productUrl)
            } else {
                product.addClass('hidden')
            }

            $(window).trigger('resize')
        }
    }
}

$(document).ready(function () {
    // Home Swiper
    new Swiper(selector, homeSwiperConfig)

    $(document).on('click', '.submitNewsletter', function (e) {
        e.preventDefault()
        $(e.currentTarget).parents('form.block_newsletter').submit()
    })
})
