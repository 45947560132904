import $ from 'jquery'
import prestashop from 'prestashop'

// State
let state = {
    display: 2,
    speed: 300
}

$(() => {
    window.accordionInit = accordionInit
    accordionInit()

})


// Init accordion
const accordionInit = function () {
    const {selector, activeClass, tile, title, content} = prestashop.themeSelectors.accordion

    // Onload we don't want to see accordions animating up
    // So instead we are hiding it straight away
    $(document).find('.accordion-tile-js:not(.active)').find(content).hide()

    $(document).on("click", `${selector} ${title}`, (e) => {
        const self = $(e.currentTarget)
        const currentTile = self.parent()
        const allowOnlyOnce = $(self).parents(selector).data("allow-only-one")
        const scrollTo = $(self).parents(selector).data("scroll-to")

        // Handle active classes

        // Handle differently if accordion has allowOnlyOnce rule
        if (allowOnlyOnce) {
            if (!$(self).hasClass(activeClass)) {
                if (!$(self).hasClass(activeClass)) {
                    $(self).parents(selector).find(tile).removeClass(activeClass)
                    $(self).parent(tile).addClass(activeClass)
                }
            }
        } else {
            if (currentTile.hasClass(activeClass)) currentTile.removeClass(activeClass)
            else currentTile.addClass(activeClass)
        }

        accordionHandleTiles(self)

        if (scrollTo) {
            setTimeout(function () {
                $('html, body').stop().animate({
                    scrollTop: $(self).parent(tile).offset().top - 120
                }, state.speed)
            }, state.speed)
        }
    })

    $(document).find('.accordion__tile').on('click', '.js-show-more', function (e) {
        e.preventDefault()


        $('.remaining__in__shop .site_work_time__items').removeClass('hidden')
        $(e.currentTarget).addClass('hidden')
        $('.js-show-less').removeClass('hidden')
    })
    $(document).find('.accordion__tile').on('click', '.js-show-less', function (e) {
        e.preventDefault()
        $(e.currentTarget).addClass('hidden')
        $('.js-show-more').removeClass('hidden')
    })

    initModifications()


    // On window resize re-init
    var rtime
    var timeout = false
    var delta = 200
    $(window).resize(function () {
        rtime = new Date()
        if (timeout === false) {
            timeout = true
            setTimeout(resizeend, delta)
        }
    })

    function resizeend() {
        if (new Date() - rtime < delta) {
            setTimeout(resizeend, delta)
        } else {
            timeout = false
            initModifications()
        }
    }
}

// Show / hide accordion tiles depending on
// Active values that were set onclick before
const accordionHandleTiles = function (self) {
    const {selector, activeClass, tile, content} = prestashop.themeSelectors.accordion

    $.each($(self).parents(selector).find(tile), function (key, value) {
        if ($(value).hasClass(activeClass))
            $(value).find(content).slideDown(state.speed)
        else
            $(value).find(content).slideUp(state.speed)
    })
}

// In some pages we need a custom solution
// For example products page where accordion
// Should become tabbed on desktop but not mobile
const initModifications = function () {
    const parent = $(document).find('.accordion-js.tabbed-accordion-js')

    // Remove if exists
    $(document).find('.accordion__tabs--list').remove()

    if (parent.length && window.matchMedia('(min-width: 769px)').matches) {
        let template = $(`<div class="accordion__tabs--list row"></div>`)

        $.each(parent.find('.accordion-title-js'), function (key, value) {
            const name = $(value).find('span').text().trim()
            const id = $(value).attr('id')

            template.append(`<div class="accordion__tabs--title" id="att-${id}" data-accordion-id="${id}">${name}</div>`)
        })

        const findActive = parent.find('.accordion-tile-js.active')
        if (findActive.length) {
            const activeID = findActive.find('.accordion-title-js').attr('id')
            template.find(`.accordion__tabs--title[data-accordion-id="${activeID}"]`).addClass('active')
        } else {
            template.find('.accordion__tabs--title').first().addClass('active')
        }

        parent.prepend(template)

        $(document).on('click', '.accordion__tabs--title', function (e) {
            const currentID = $(e.currentTarget).data('accordion-id')
            $('.accordion-title-js#' + currentID).trigger('click')
            parent.find('.accordion__tabs--title').removeClass('active')
            parent.find(`.accordion__tabs--title[data-accordion-id="${currentID}"]`).addClass('active')
            // console.log('EVENT ', e)
        })
    }
}

export default accordionInit
