import $ from "jquery"
import toastr from "toastr"
import {validateInputs} from "./forms-validate"

$(document).ready(() => {
    window.handleForm = handleForm

    function forceNumeric() {
        var $input = $(this)
        $input.val($input.val().replace(/[^\d]+/g, ""))
    }

    $("body").on(
        "propertychange input",
        'input[name="phone_mobile"], input[name="phone"]',
        forceNumeric
    )
    $('.section--form .js-form .js-submit-form').on('click', submitForm)
})


const submitForm = async e => {
    e.preventDefault()

    const form = $('form.js-form')
    const inputs = form.find('input:visible, select, textarea')
    form.find('.alert').remove()
    const validate = await window.validateInputs(inputs)
    if (validate) {
        window.buttonAddLoader($('.js-submit-form'))
        form.submit()
    }
}

const handleForm = (form, callback, additionalFields, preventRelaod) => {
    form.find(".error__message, .alert.alert--red").remove()

    let data = {...additionalFields}
    const inputs = form.find("select, input:visible, textarea")

    $.each(inputs, function (index, value) {
        data[$(value).attr("name")] = $(value).val()
    })

    // Todo: reik sugalvoti sprendimo ar if: if comments, nes dabar kaip ir globalus funkcionalumas, bet ant grade paliktas.
    if (form.find("div[data-input]").length) {
        data[form.find("div[data-input]").data("input")] = form.find('.grade-stars .star-on').length
    }

    validateInputs(inputs).then((validate) => {
        if (validate) {
            const btn = form.find(".js-submit-form")
            window.buttonAddLoader(btn)

            const action = form.attr("action") || form.attr('data-action')
            const type = form.attr("type")
            const token = btn.attr("data-token")

            if (token) data.token = token

            $.ajax({url: action, type, data})
                .done(function (response) {
                    if (response.success || response.status) {
                        toastr.success(btn.data('success-message'), btn.data('success-title'))
                        form.find("input, textarea, select").val("")
                        if (callback.success) callback.success()

                        if (!preventRelaod) window.location.reload()
                    } else {
                        if (typeof response.errors === 'object' && response.errors !== null) {
                            let errorsList = {}
                            Object.keys(response.errors).map(function (key, value) {
                                errorsList[key] = {
                                    ...response.errors[key],
                                    messages: `<div class='alert alert--red row middle center'><span class="highlighted">${response.errors[key].messages}</span></div>`
                                }
                            })
                            window.displayErrorMessages(errorsList)
                            if (callback.fail) callback.fail(errorsList)
                        } else {
                            if (callback.fail) callback.fail(response.error)
                        }
                    }
                })
                .fail(function (err) {
                    toastr.error(btn.data('fail-message'), btn.data('fail-title'))
                    if (callback.fail) callback.fail(err)
                })
                .always(function () {
                    window.buttonRemoveLoader(form.find(".js-submit-form"))
                    if (callback.always) callback.always()
                })
        }
    })
}
