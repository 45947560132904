// Module responsible for every modal on the page

// EXAMPLE URL TO MODAL
// <a href="[custom modal id]" class="trigger__modal" data-modal-type="[type of modal]"></a>
// Modal types: popup | modal

// EXAMPLE MODAL
// One of the classes bellow is a must and should match button type.
// In case if the same id would like to open different modals on some kind of condition.
// Id should match button href. This is a confirmation that we want to open this specific modal.
//<div class="[page__popup | page__modal] hidden" id="[custom id]"></div>

import $ from "jquery"
import prestashop from "prestashop"

const modals = prestashop.themeSelectors.modals // Selector for main classes
const animationTime = 300 // Transition duration. Ideally this should match css transition duration

const openModal = (e) => {
    e.preventDefault()
    $("body").addClass('overflow__hidden')

    const modalID = $(e.currentTarget).attr("href")
    const modalType = $(e.currentTarget).attr("data-modal-type")
    const modalCreateBackdrop = $(`${modalID}.page__${modalType}`).attr('data-create-backdrop')

    if (modalCreateBackdrop) {
        const underlay = $(`<div class="modal__underlay modal__close remove__self hidden" id="${modalID.replace("#", "")}"></div>`)
        underlay.on('click', closeModal)

        $(`${modalID}.page__${modalType}`)
            .after(underlay)
    }

    $(`${modalID}.page__${modalType}`).removeClass('hidden')
    setTimeout(() => {
        $(`${modalID}.page__${modalType}`).addClass(modals.activeClass)
    }, 100)

    if (modalCreateBackdrop) {
        $(modals.underlay + modalID).removeClass('hidden')
        setTimeout(() => {
            $(modals.underlay + modalID).addClass(modals.activeClass)
        })
    } else {
        $(modals.underlay).removeClass('hidden')
        setTimeout(() => {
            $(modals.underlay).addClass(modals.activeClass)
        })
    }
}

const closeModal = (e) => {
    e?.preventDefault()

    $("body").removeClass('overflow__hidden')
    $(".nav__primary").removeClass('active')

    $(modals.selector).removeClass(modals.activeClass)
    $(modals.underlay).removeClass(modals.activeClass)

    if (!$(modals.selector).hasClass('modal__prevent--hide'))
        setTimeout(() => {
            $(modals.selector).addClass('hidden')
            $(modals.underlay).addClass('hidden')

            if ($(e.currentTarget).hasClass('remove__self')) $(e.currentTarget).remove()

        }, animationTime)
}

const changeModal = (e) => {
    e.preventDefault()
    const changeTo = $(e.target).data("change")
    $(e.target).parents(modals.selector).removeClass(modals.activeClass)
    $(".page__modal#" + changeTo + ", .page__popup#" + changeTo).removeClass('hidden')
    setTimeout(() => {
        $(".page__modal#" + changeTo + ", .page__popup#" + changeTo)
            .addClass(
                "active"
            )
    }, 100)
}

// Export all functions in case we would like to use it in another function
const init = () => {
    $(document).on("click", modals.trigger, openModal)
    $(document).on("click", modals.close, closeModal)
    $(document).on("click", modals.change, changeModal)

    window.elp_modals = {init, closeModal, openModal}
}

$(document).ready(() => {
    init()
})
export default {init, closeModal, openModal}
