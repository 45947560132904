// Utils and helpers
import "babel-core/register"
import "babel-polyfill"

import './selectors'
import './_helpers'

// Pages
import './checkout'
import './customer'
import './listing'
import './product'
import './cart'
import './manufacturer'
import './home'

// Components
import './components/shopping-cart'
import './components/header'
import './components/drop-down'
import './components/accordion'
import './components/modals'
import './components/tabs'
import './components/navigation'
import './components/scroll-to'
import './components/forms-handle'
import singleProduct from './components/single-product'

// Libs
import './lib/jquery.rating.plugin'
import './lib/jquery.nice-select'
import simpleAnimations from './lib/simple-animations'

// Modules
import $ from 'jquery'
import Swiper from 'swiper'
import tippy from 'tippy.js'
import toastr from 'toastr'
import prestashop from 'prestashop'
import 'simplebar'

$(function () {
    // Register to window
    window.Swiper = Swiper
    window.tippy = tippy
    window.toastr = toastr
    // window.initGrades = initGrades
    window.simpleAnimations = simpleAnimations
    simpleAnimations()
    singleProduct()

    // tippy
    tippy('[data-tippy-content]', {
        theme: 'dark',
        popperOptions: {
            strategy: "fixed",
            modifiers: [
                {
                    name: "flip",
                    options: {
                        fallbackPlacements: ["top"]
                    }
                }
            ]
        }
    })

    // nice select
    $('select.nice-select').niceSelect()

    $('label a').on('click', function (e) {
        e.stopPropagation()
    })

    // Stars
    $(".grade-stars").rating()

    // Display loader on button redirect
    $(document).on('click', '.js-display-loader', e => {
        window.buttonAddLoader($(e.currentTarget))
    })

    // Toggle footer links on mobile
    $('.footer__box .footer__link--block h3').on('click', toggleFooterBlock)

    // Featured swiper
    if ($('.section--featuredproducts').length) {
        new Swiper(`.section--featuredproducts .swiper-container`, {
            slidesPerView: 'auto',
            centeredSlides: false,
            spaceBetween: 10,
            // autoplay: {
            //     delay: 2500,
            //     disableOnInteraction: true,
            // },
            navigation: {
                nextEl: `.section--featuredproducts .swiper-button-next`,
                prevEl: `.section--featuredproducts .swiper-button-prev`
            },
            pagination: {
                el: `.section--featuredproducts .swiper-pagination`,
                dynamicBullets: true,
                clickable: true
            },
            breakpoints: {
                1200: {
                    slidesPerView: 4,
                    centeredSlides: false
                },
                992: {
                    slidesPerView: 3,
                    centeredSlides: false,
                    spaceBetween: 10
                }
            }
        })
    }
    // To pass SEO 100%

    $(document).on('click', '.js-google-login', function (e) {
        e.preventDefault()
        window.open(e.currentTarget, 'popup', 'width=450,height=300,left=500,top=500')
    })
    $(document).on('click', '.js-google-facebook', function (e) {
        e.preventDefault()
        window.open(e.currentTarget, 'popup', 'width=450,height=300,left=500,top=500')
    })
})

const toggleFooterBlock = e => {
    const parent = $(e.currentTarget).parents('.footer__link--block')
    if (parent.hasClass('active')) parent.removeClass('active')
    else parent.addClass('active')
}
