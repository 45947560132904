import $ from "jquery"
import prestashop from "prestashop"

// Init
const init = () => {
    $(document).on("click", ".js-add-to-cart", addToCart) // Add to cart
    $(document).on("click", ".js-trigger-add-to-cart", function (e) {
        let cart_btn = $('.js-add-to-cart') // UR-187
        // UR-187
        if (!$(e.currentTarget).hasClass('js-no-multiaccessories')) {
            $(cart_btn).data('multiaccessoriesclick', true)
        }
        $(cart_btn).trigger('click')
    }) // Trigger add to cart


    $('.product__multiaccessories--check input[type="checkbox"]').on('change', function () {
        let selector = $(document).find('.product__multiaccessories--check input[type="checkbox"]:checked')

        if (selector.length) $('.add__to__cart--details').removeClass('disabled')
        else $('.add__to__cart--details').addClass('disabled')
    })
}

// Add product to cart
const addToCart = (e) => {
    e.preventDefault()

    // Add loadersW
    window.buttonAddLoader($(e.currentTarget))
    window.buttonAddLoader($('.js-trigger-add-to-cart'))

    // Collect data
    const url = $('form#add-to-cart-or-refresh').attr('action')
    const token = $("input[name='token']").val()
    const id_product = $("input#product_page_product_id").val()
    const id_customization = $('input#product_customization_id').val()
    const qty = $('.product-add-to-cart .js-quantity-value').val()

    // Collect all variants
    let attributes = {}
    $.each($(".variants__list"), function (index, value) {
        const groupName = $(value).data("group-name")
        const groupType = $(value).data("group-type")
        let currentValue

        if (groupType === "select" || groupType === "color")
            currentValue = $(value).find("select").val()
        else if (groupType === "radio" || groupType === "quads")
            currentValue = $(value).find("input:checked").val()

        attributes[groupName] = currentValue
    })

    let id_accessories = ""
    let id_accessories_attributes = ""

    let to_add_accessories = !!$(e.currentTarget).data('multiaccessoriesclick') // UR-187

    if (to_add_accessories) { // UR-187
        $.each(
            $(".js-multiaccessories-body .product__multiaccessories--check"),
            function (key, value) {
                if ($(value).find("input").is(":checked")) {
                    const separator = id_accessories === "" ? "" : ","
                    const attrseparator = id_accessories_attributes === "" ? "" : ","
                    id_accessories += separator + $(value).data("accessory-id")
                    id_accessories_attributes +=
                        attrseparator + $(value).data("accessory-attr")
                }
            }
        )

        $(e.currentTarget).data('multiaccessoriesclick', false) // UR-187
    }

    $.ajax({
        url,
        method: "POST",
        data: {
            token,
            id_product,
            id_customization,
            id_accessories,
            id_accessories_attributes,
            add: 1,
            qty,
            action: 'update',
            ...attributes
        },
        dataType: "json"
    }).then(function (resp) {
        if (resp.success) {
            // Success
            prestashop.emit('updateCart', {
                reason: {
                    idProduct: resp.id_product,
                    idProductAttribute: resp.id_product_attribute,
                    idCustomization: resp.id_customization,
                    linkAction: 'update',
                    cart: resp.cart
                },
                resp: resp
            })

            const cartLabel = $('.nav__main .btn__cart span')
            if (resp.quantity < 1) {
                cartLabel.text('')
                cartLabel.addClass('hidden')
                $('.nav__main .btn__cart span').removeClass('active')
            } else {
                cartLabel.text(resp.quantity)
                cartLabel.removeClass('hidden')
                $('.nav__main .btn__cart span').addClass('active')
            }

            $('.nav__main .btn__cart').trigger('click')
        } else {
            // Errors
            toastr.error(resp.errors[0], $(document).find('.product-add-to-cart .cart__actions').data('fail-title'))
        }
    }).fail(function (err) {
        console.log('ERR ', err)
    }).always(function () {
        window.buttonRemoveLoader($('.js-add-to-cart'))
        window.buttonRemoveLoader($('.js-trigger-add-to-cart'))
    })
}

export default init
export {addToCart}
