import $ from 'jquery'
import prestashop from 'prestashop'

if(prestashop.page.page_name === 'manufacturer') {
    // State
    let state = {
        onLoadVisible: 20
    }

    $(document).ready(function () {
        // Handle onload list
        init()

        // Listeners
        $(document).find('.js-brands-show-all').on('click', showAll)
        $(document).find('.js-brands-show-initial').on('click', showInitial)
    })

    const init = function () {
        $.each($('.section--brands .brands .brand'), function (key, value) {
            if(key < state.onLoadVisible) {
                $(value).removeClass('hidden')
            }
        })

        $(document).find('.js-brands-show-all b').text('('+$('.section--brands .brands .brand').length+')')
    }

    const showAll = function (e) {
        e.preventDefault()
        // Show all
        $('.section--brands .brands .brand').removeClass('hidden')
        $('.js-brands-show-initial').removeClass('hidden')
        $('.js-brands-show-all').addClass('hidden')
    }

    const showInitial = function (e) {
        e.preventDefault()
        // Show initial
        $('.section--brands .brands .brand').addClass('hidden')
        $('.js-brands-show-initial').removeClass('hidden')
        $('.js-brands-show-all').removeClass('hidden')
        init()
    }
}