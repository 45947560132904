import prestashop from 'prestashop'
import $ from 'jquery'

prestashop.themeSelectors = {
    accordion: {
        selector: ".accordion-js",
        title: ".accordion-title-js",
        tile: ".accordion-tile-js",
        activeClass: "active"
    },
    modals: {
        selector: ".page__popup, .page__modal",
        trigger: ".trigger__modal",
        underlay: ".modal__underlay",
        activeClass: "active",
        close: ".modal__close, .btn__close",
        change: ".modal__change"
    },
    options: {
        selector: ".js-cart-options",
        actions: ".js-cart-action",
        increase: ".js-increase-product-quantity",
        decrease: ".js-decrease-product-quantity",
        amount: ".js-current-amount input",
        remove: "js-remove-product",
        input: ".js-current-amount input"
    },
    ajaxCart: {
        quantityValue: ".js-quantity-value",
        quantityPlain: ".js-quantity-plain",
        totalQuantity: ".js-total-quantity",
        productTotal: ".js-product-total",
        priceTaxExl: ".js-price-total-tax-exl",
        priceTaxTotal: ".js-tax-total",
        priceTaxInc: ".js-price-total-tax-inc"
    },
    scrollTo: {
        trigger: ".scroll-to-element-js",
        target: ".scroll-to-target-js"
    },
    inputs: {
        togglePassword: '.toggle-password-js'
    },
    niceSelect: ".nice__select"
}

$(document).ready(() => {
    prestashop.emit('themeSelectorsInit')
})
