import $ from 'jquery'
import {openModal} from './components/modals'

$(document).ready(() => {
    $("#business_address").on("change", toggleFields)
    $(document).on("click", ".js-display-mobile-menu", toggleMenu)
    $(document).on("click", ".section__sidebar--close", closeMenu)

    let placeholderLoader = $('#elp-rules__modal .placeholder__loader').clone()
    $('.section--auth .trigger__modal').on('click', function (e) {
        e.preventDefault()

        var elp_service_cms = $(this).data("link")
        if (typeof elp_service_cms !== "undefined") {
            $(document).find(".elp-rules__modal .js-modal-content").html(placeholderLoader)
            $.get(elp_service_cms + "?content_only=1", function (data) {
                let response = $(data).find('#cms-page-content')
                let head = response.find('h1.section__head--title').text()
                response.find('.section__head').remove()
                $(document).find(".elp-rules__modal .modal__head").append('<h3>'+head+'</h3>')
                $(document).find(".elp-rules__modal .js-modal-content").html(response)
            })
        }

        window.elp_modals.openModal(e)
    })
})

const toggleMenu = e => {
    e.preventDefault()
    $('.section__sidebar--left').toggleClass('active')
}

const closeMenu = e => {
    e.preventDefault()
    $('.section__sidebar--left').removeClass('active')
}


const toggleFields = (e) => {
    if ($(e.target).is(":checked")) {
        $("#address .input__wrapper--company").css({display: "flex"})
        $("#address .input__wrapper--dni").css({display: "flex"})
        $("#address .input__wrapper--vat_number").css({display: "flex"})
    } else {
        $("#address .input__wrapper--company").css({display: ""})
        $("#address .input__wrapper--dni").css({display: ""})
        $("#address .input__wrapper--vat_number").css({display: ""})
    }
}
