import Swiper from "swiper"
import $ from "jquery"
import prestashop from 'prestashop'
import config from './modules.config'

// State
let state = {
    maxItemsPerList: 5
}

const initModules = () => {
    // Init deals
    new Swiper(config.dealsSelector, config.dealsConfig)

    // Init subcategories
    if ($('#product .section__subcategories').length) {
        $.each($('#product .section__subcategories'), (key, value) => {
            new Swiper(`#${$(value).attr('id')} .swiper-container`, {
                slidesPerView: 'auto',
                centeredSlides: false,
                spaceBetween: 15,
                // autoplay: {
                //     delay: 2500,
                //     disableOnInteraction: true,
                // },
                navigation: {
                    nextEl: `#${$(value).attr('id')} .swiper-button-next`,
                    prevEl: `#${$(value).attr('id')} .swiper-button-prev`
                },
                pagination: {
                    el: `#${$(value).attr('id')} .swiper-pagination`,
                    dynamicBullets: true,
                    clickable: true
                },
                breakpoints: {
                    1200: {
                        slidesPerView: 5,
                        centeredSlides: false
                    },
                    992: {
                        slidesPerView: 4,
                        centeredSlides: false,
                        spaceBetween: 10
                    }
                }
            })
        })
    }

    // Init delivery list
    loadDeliveryList(true)
    // Init multiaccessories
    loadMultiaccessories()
    $(document).on('click', '.js-show-more-items', showMoreItems)
    $(document).on('click', '.js-show-less-items', showLessItems)
    $(document).on('change', '.product__multiaccessories--check input', loadMultiaccessories)
    precheckAccessories() // UR-187 (3p)
}

// Hide / show items in delivery list accordion
const loadDeliveryList = function (load) {
    const list = $('.product-information .list__deliveries li')

    $.each(list, function (key, value) {
        if (key < state.maxItemsPerList) {
            // console.log('IF')
        } else {
            $(value).addClass('hidden')
        }
    })

    if (load && (list.length > state.maxItemsPerList)) {
        $('.js-show-more-items').removeClass('hidden')
    }
}

const showMoreItems = function (e) {
    e.preventDefault()
    $('.js-show-less-items').removeClass('hidden')
    $('.js-show-more-items').addClass('hidden')
    $('.product-information .list__deliveries li').removeClass('hidden')
}

const showLessItems = function (e) {
    e.preventDefault()
    $('.js-show-less-items').addClass('hidden')
    $('.js-show-more-items').removeClass('hidden')

    loadDeliveryList()
}

// Load multi accessories module
const loadMultiaccessories = function () {
    if($('.js-multiaccessories-body').length) getCurrentPrice()
}

// UR-187 (3p) Pre-check accessories
const precheckAccessories = function () {
    $.each($('.product__multiaccessories--slide .product__multiaccessories--check input'), function (_, value) {
        if (!($(value).is(':checked'))) {
            $(value).click()
        }
    })
}

// Get current multi accessories price
const getCurrentPrice = function () {
    // UR-187 (2p)
    let currentItem = $('.product__multiaccessories--wrapper')[0]
    let currentPrice = parseFloat($(currentItem).find('.current-price span').data('price'))
    let currentDiscount = parseFloat($(currentItem).find('.regular-price').data('price'))

    $.each($('.product__multiaccessories--slide .product__multiaccessories--check input'), function (key, value) {
        if($(value).is(':checked')) {
            // UR-187 (2p)
            let curr_price = parseFloat($(value).parents('.product__multiaccessories--wrap').find('.current-price span').data('price'))
            let disc_price = parseFloat($(value).parents('.product__multiaccessories--wrap').find('.regular-price').data('price'))

            // UR-187 (2p)
            currentPrice = parseFloat(currentPrice + curr_price);
            currentDiscount = (!currentDiscount) ? parseFloat(currentDiscount + disc_price) : parseFloat(currentDiscount + ((!disc_price) ? curr_price : disc_price))
        }
    })

    currentPrice = (currentPrice).toFixed(2)

    $('.add__to__cart--details .new__price').text(currentPrice + ' ' + prestashop.currency.sign)

    if (!window.isNaN(currentDiscount)) {
        currentDiscount = (currentDiscount).toFixed(2)

        $('.add__to__cart--details .old__price').text(currentDiscount + ' ' + prestashop.currency.sign)

        let percentageDifference = `${parseInt(100 - ((currentPrice * 100) /currentDiscount))}%`
        $('.js-multiaccessories-discount').text(percentageDifference)

        $('.product__multiaccessories-saving').removeClass('hidden');
        $('.product__multiaccessories--buy .old__price').removeClass('hidden');
    }
}

export default initModules
