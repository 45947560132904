import $ from "jquery"
import prestashop from 'prestashop'

$(document).ready(() => {
    $(document).on('click', '.btn.proceed', addLoader)
    $(document).on('submit', '#promo-code form', submitPromoCode)

    $(document).on('click', 'a.delete-voucher', function (e) {
        e.preventDefault();

        const voucherUrl = $(this).attr('href')
        const voucherBlock = $(this).closest('.voucher');
        voucherBlock.addClass('loader--visible')

        removePromoCode(voucherUrl, (status) => {
            if (status) {
                voucherBlock.remove()
                triggerCartUpdate()
            }

            voucherBlock.removeClass('loader--visible')
        })
    });

    $('#return-to-cart').on('click', function (e) {
        window.location.href = prestashop.urls.pages.cart + '?action=show'
    })

    $('.open--delivery-payment').on('click', (e) => {
        e.preventDefault()
        $('.single__step#checkout-delivery-step').trigger('click')
    })

    $('.open--delivery-payment').on('click', (e) => {
        e.preventDefault()
        $('.single__step#checkout-delivery-step').trigger('click')
    })

})

const removePromoCode = (voucherUrl, callback) => {
    try {
        $.ajax({
            type: 'POST',
            cache: false,
            dataType: 'text',
            url: voucherUrl,
            success: function (response) {
                callback(true)
            },
            error() {
                callback(false)
            }
        });
    } catch (error) {
        console.log('Užklausos klaida: ' + error);
        toastr.error(checkoutErrorLabels(ajaxError));
    }
}

const submitPromoCode = (e) => {
    e.preventDefault()

    const promoForm = $('#promo-code form')

    const data = {
        token: promoForm.find('input[name="token"]').val(),
        addDiscount: 1,
        discount_name: promoForm.find('.promo-input').val(),
        ajax: 1,
        action: 'update'
    }

    console.log(promoForm.attr('action'));

    window.buttonAddLoader($('#promo-code form .btn'))

    $.ajax({
        type: "POST",
        url: promoForm.attr('action'),
        data,
        dataType: 'json'
    }).done(function (response) {
        if (!response.hasError) {
            triggerCartUpdate()
        } else {
            if ($('#promo-code .alert.js-error').length === 0) {
                $("<div class=\"alert alert--red row middle center js-error hidden\"></div>").insertAfter(".submitPromoCode");
            }

            $('#promo-code .alert.js-error').text(response.errors[0]).removeClass('hidden')
        }
    }).always(function () {
        window.buttonRemoveLoader($('#promo-code form .btn'))
    })
}

const triggerCartUpdate = () => {
    prestashop.emit('updateCart',
        {reason: {linkAction: 'refresh'}, resp: {}}
    )
}

const addLoader = (e) => {
    window.buttonAddLoader($(e.currentTarget))
}
