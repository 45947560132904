import $ from "jquery"

// Global vars
let scrollTimeout
let throttle = 100
const selector = $('#product .productbar--fixed')

const initStickyBar = () => {
    // Handle visibility
    if (selector.length)
        $(window).scroll(() => {
            if (!scrollTimeout) {
                scrollTimeout = setTimeout(function () {
                    if (window.pageYOffset > window.innerHeight) {
                        addSticky()
                    } else {
                        removeSticky()
                    }
                    scrollTimeout = null
                }, throttle)
            }
        })

    // Synchronize
    $(document).on("change", '.js-quantity-value', function (e) {
        $(document).find('.js-quantity-value').val($(e.currentTarget).val())
    })
}

const removeSticky = () => {
    if (selector.hasClass('visible')) {
        selector.removeClass('visible')
    }
}

const addSticky = () => {
    if (selector.hasClass('visible') === false) {
        selector.addClass('visible')
    }
}

export default initStickyBar