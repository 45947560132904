import $ from 'jquery'
import prestashop from 'prestashop'
import Swiper from 'swiper'

// State
const state = {
    selector: '.listing__subcategories .swiper-container',
    config: {
        slidesPerView: 'auto',
        navigation: {
            nextEl: ".listing__subcategories .swiper-button-next",
            prevEl: ".listing__subcategories .swiper-button-prev"
        },
        pagination: {
            el: ".listing__subcategories .swiper-pagination",
            clickable: true,
            dynamicBullets: true
        },
        // shortSwipes: false,
        spaceBetween: 20
    }
}

// Onload
$(document).ready(() => {
    let select = $('select#n')
    let sort = $('select#sort-by')

    // Items per page
    select.val($('#af_nb_items').val())
    select.on('change', function () {
        $('#af_nb_items').val(select.val())
    })

    // Sorting
    sort.on('change', function (e) {
        $('#af_orderBy').val(sort.find('option[value=' + $(this).val() + ']').attr('data-field')).change()
        $('#af_orderWay').val(sort.find('option[value=' + $(this).val() + ']').attr('data-direction')).change()
    })

    // Toggle filters
    $(document).on('click', '.js-hide-filters', displayWide)
    $(document).on('click', '.js-show-filters', displayThick)
    $(document).on('click', '.js-trigger-filters', openMobileFilters)
    $('.sidebar__backdrop').on('click', closeMobileFilters)

    let subcategoriesSwiper = new Swiper(state.selector, state.config) // Init subcategories

    $(document).on('click', '.listing__subcategories--all a', function (e) {
        e.preventDefault()

        if ($('.listing__subcategories').hasClass('swiper-destroyed')) {
            $('.listing__subcategories').removeClass('swiper-destroyed')
            $('.js-show-all-cats').removeClass('hidden')
            $('.js-show-less-cats').addClass('hidden')
            subcategoriesSwiper = new Swiper(state.selector, state.config)
        } else {
            $('.listing__subcategories').addClass('swiper-destroyed')
            subcategoriesSwiper.destroy()
            $('.js-show-all-cats').addClass('hidden')
            $('.js-show-less-cats').removeClass('hidden')
        }
    })
        hideSwiperIfTwoProducts();
})

const hideSwiperIfTwoProducts = () => {
    const listingSubcategoryWrapper = $('.section--listing .swiper-container');
    const listingSubcategory = $('.section--listing .listing__subcategories .swiper-slide');
    const allSubBtn = $('.section--listing .listing__subcategories--all');
    const pagination = $('.section--listing .swiper-pagination');
    const swiperWrapper = $('.listing__subcategories .swiper-wrapper');
    const swiperButton = $('.listing__subcategories .swiper-button');

    if(listingSubcategory.length < 3) {
        listingSubcategoryWrapper.addClass('no-swiper')
        swiperWrapper.addClass('disabled');
        pagination.addClass('disabled')
        allSubBtn.addClass('hidden');
        swiperButton.addClass('disabled');
    }  else {
        listingSubcategoryWrapper.removeClass('no-swiper')
        swiperWrapper.removeClass('disabled');
        pagination.removeClass('disabled')
        allSubBtn.removeClass('hidden');
        swiperButton.removeClass('disabled');
    }
}

// Open mobile filters
const openMobileFilters = (e) => {
    e.preventDefault()

    $('.section__sidebar--left').addClass('active')
    $('.sidebar__backdrop').addClass('active')
}

// Close mobile filters
const closeMobileFilters = (e) => {
    e?.preventDefault()

    $('.section__sidebar--left').removeClass('active')
    $('.sidebar__backdrop').removeClass('active')
}

// Display wide list ( without sidebar )
const displayWide = e => {
    e.preventDefault()
    if (window.matchMedia('(max-width: 992px)').matches) {
        closeMobileFilters()
    } else {
        $('.section--listing.thick').addClass('wide').removeClass('thick')
        $('.js-show-filters').removeClass('hidden')
    }
}

// Display thick list ( with sidebar )
const displayThick = e => {
    e.preventDefault()
    $('.section--listing.wide').addClass('thick').removeClass('wide')
    $('.js-show-filters').addClass('hidden')
}