import $ from "jquery"

// Product video load
const loadVideo = () => {
    let embed = $('.video__wrapper').data('embed')

    if (embed) {
        let video_id;
        if (embed.toLowerCase().indexOf("v=") >= 0) {
            video_id = embed.split('v=')[1]
            let ampersandPosition = video_id.indexOf('&')
            if (ampersandPosition != -1) {
                video_id = video_id.substring(0, ampersandPosition)
            }
        } else {
            video_id = embed;
        }

        let source =
            "https://img.youtube.com/vi/" +
            video_id +
            "/sddefault.jpg"
        let image = new Image()
        image.src = source
        image.addEventListener(
            "load",
            (function () {
                $('.video__wrapper .play--icon-wrap').after(image)
            })
        )
    }


    $(document).on("click", ".js-play-product-video", playVideo) // Add to cart
}

const playVideo = (e) => {
    const parent = $(e.currentTarget).parents('.video__wrapper')

    let video_id = $('.video__wrapper').data('embed')
    if (video_id.length > 0) {
        const iframe = $('<iframe></iframe>')

        iframe.attr('loading', 'lazy')
        iframe.attr('src', 'https://www.youtube.com/embed/' + video_id + '?autoplay=1')
        iframe.attr('frameborder', '0')
        iframe.attr('allowfullscreen', 'true')
        iframe.attr('allow', 'autoplay')

        parent.find('.play--icon-wrap').after(iframe)
        parent.find('.play--icon-wrap').remove()
        parent.find('img').remove()
    }
}

export default loadVideo
export {
    playVideo
}